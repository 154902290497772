var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageheading } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(" " + _vm._s(_vm.pageheading.toLocaleUpperCase()) + " ")]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading
          ? _c("div", [_c("Loading")], 1)
          : _c(
              "div",
              {
                staticClass: "annual-questionnaire-form",
                attrs: { id: "body-content-area" },
              },
              [
                _c("div", [
                  _c(
                    "form",
                    {
                      staticStyle: { margin: "0 auto" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.validateBeforeSubmit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      !_vm.isViewOnly
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "padding-bottom": "20px",
                                width: "100%",
                                display: "flex",
                                "justify-content": "flex-end !important",
                                gap: "0.5rem",
                              },
                              attrs: { id: "buttongroup" },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "max-width": "60px" } },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "button is-accent has-text-white",
                                      attrs: {
                                        disabled: !_vm.$hasPermissions(
                                          _vm.clientSession,
                                          ["ANNUAL_QUESTIONNAIRE"],
                                          2
                                        ),
                                        type: "submit",
                                      },
                                    },
                                    [_vm._v(" Save ")]
                                  ),
                                ]
                              ),
                              _c("div", {}, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button is-light",
                                    on: {
                                      click: function ($event) {
                                        _vm.resetForm()
                                        _vm.$router.go(-1)
                                      },
                                    },
                                  },
                                  [_vm._v("Cancel")]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.isViewOnly
                        ? _c("div", { staticClass: "columns" }, [
                            _c(
                              "div",
                              {
                                staticClass: "column",
                                staticStyle: { "max-width": "50%" },
                              },
                              [
                                _vm._m(0),
                                _c("div", { staticClass: "block" }, [
                                  _c("div", { staticClass: "flex-container" }, [
                                    _c("div", { staticClass: "flex-field" }, [
                                      _vm._m(1),
                                      _c("div", { staticClass: "field" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.name,
                                              expression: "name",
                                            },
                                          ],
                                          staticStyle: { "max-width": "320px" },
                                          attrs: { type: "text" },
                                          domProps: { value: _vm.name },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.name = $event.target.value
                                            },
                                          },
                                        }),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "flex-field" },
                                      [
                                        _vm._m(2),
                                        _c("kendo-datepicker", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          staticStyle: { "max-width": "150px" },
                                          attrs: {
                                            id: "dueDate",
                                            name: "due date",
                                            format: "yyyy-MM-dd",
                                          },
                                          model: {
                                            value: _vm.input.dueDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.input,
                                                "dueDate",
                                                $$v
                                              )
                                            },
                                            expression: "input.dueDate",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.errors.has("due date"),
                                                expression:
                                                  "errors.has('due date')",
                                              },
                                            ],
                                            staticClass: "help is-danger",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.errors.first("due date")
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "flex-field" },
                                      [
                                        _vm._m(3),
                                        _c("kendo-datepicker", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          staticStyle: { "max-width": "150px" },
                                          attrs: {
                                            id: "asOfDate",
                                            name: "info date",
                                            format: "yyyy-MM-dd",
                                          },
                                          model: {
                                            value: _vm.input.asOfDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.input,
                                                "asOfDate",
                                                $$v
                                              )
                                            },
                                            expression: "input.asOfDate",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.errors.has("info date"),
                                                expression:
                                                  "errors.has('info date')",
                                              },
                                            ],
                                            staticClass: "help is-danger",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.errors.first("info date")
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex-container",
                                      staticStyle: { "margin-top": "1rem" },
                                    },
                                    [
                                      _c("div", { staticClass: "flex-field" }, [
                                        _vm._m(4),
                                        _c("div", { staticClass: "field" }, [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.input.portalMessage,
                                                expression:
                                                  "input.portalMessage",
                                              },
                                            ],
                                            attrs: { rows: "5", cols: "50" },
                                            domProps: {
                                              value: _vm.input.portalMessage,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.input,
                                                  "portalMessage",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "column",
                                staticStyle: { "max-width": "50%" },
                              },
                              [
                                _vm._m(5),
                                _vm._l(
                                  _vm.notificationsTable,
                                  function (reminder, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: i,
                                        staticClass: "flex-container flex-left",
                                        staticStyle: {
                                          "margin-top": "1rem",
                                          width: "100%",
                                          gap: "0.5rem !important",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "flex-field",
                                            staticStyle: {
                                              "max-width": "150px",
                                            },
                                          },
                                          [
                                            _vm._m(6, true),
                                            _c("kendo-datepicker", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                id: "reminderDate",
                                                format: "yyyy-MM-dd",
                                              },
                                              model: {
                                                value: reminder.remindAt,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    reminder,
                                                    "remindAt",
                                                    $$v
                                                  )
                                                },
                                                expression: "reminder.remindAt",
                                              },
                                            }),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: reminder.remindAt,
                                                  expression:
                                                    "reminder.remindAt",
                                                },
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: false,
                                                  expression: "false",
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'",
                                                },
                                              ],
                                              attrs: {
                                                name: "reminder date " + i,
                                              },
                                              domProps: {
                                                value: reminder.remindAt,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    reminder,
                                                    "remindAt",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.errors.has(
                                                      "reminder date " + i
                                                    ),
                                                    expression:
                                                      "errors.has(`reminder date ${i}`)",
                                                  },
                                                ],
                                                staticClass: "help is-danger",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    "The reminder date field is required"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex-field flex-left flex-container",
                                            staticStyle: {
                                              width:
                                                "calc(100% - 0.5rem - 200px) !important",
                                              position: "relative",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "flex-field",
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _vm._m(7, true),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "select",
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              reminder.emailTemplateId,
                                                            expression:
                                                              "reminder.emailTemplateId",
                                                          },
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate",
                                                            value: "required",
                                                            expression:
                                                              "'required'",
                                                          },
                                                        ],
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          name:
                                                            "email template " +
                                                            i,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$selectedVal =
                                                              Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function (o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function (
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                            _vm.$set(
                                                              reminder,
                                                              "emailTemplateId",
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          },
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.emailTemplates.filter(
                                                          function (x) {
                                                            return (
                                                              _vm
                                                                .QuestionnaireType
                                                                .id ===
                                                              x.emailTemplateTypeCategoryId
                                                            )
                                                          }
                                                        ),
                                                        function (opt) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: opt.id,
                                                              domProps: {
                                                                value: opt.id,
                                                                selected:
                                                                  parseInt(
                                                                    opt.id
                                                                  ) ===
                                                                  parseInt(
                                                                    reminder.emailTemplateId
                                                                  ),
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    opt.name
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.errors.has(
                                                                "email template " +
                                                                  i
                                                              ),
                                                            expression:
                                                              "errors.has(`email template ${i}`)",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "help is-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            "The email template field is required"
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "flex-field",
                                            staticStyle: {
                                              padding: "0",
                                              "padding-top": ".5rem",
                                            },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "label is-size-7",
                                              },
                                              [_vm._v("   ")]
                                            ),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "button is-light",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeReminder(
                                                      reminder
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("-")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      display: "flex",
                                      "justify-content": "flex-end",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "button is-light",
                                        staticStyle: {
                                          "margin-left": "auto",
                                          "margin-top": "0.5rem",
                                        },
                                        on: { click: _vm.addReminder },
                                      },
                                      [_vm._v("Add reminder")]
                                    ),
                                  ]
                                ),
                                _vm._m(8),
                                _c(
                                  "div",
                                  {
                                    staticClass: "select",
                                    staticStyle: { width: "100%" },
                                  },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.input
                                                .defaultSentEmailTemplate,
                                            expression:
                                              "input.defaultSentEmailTemplate",
                                          },
                                        ],
                                        staticStyle: { width: "100%" },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.input,
                                              "defaultSentEmailTemplate",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      _vm._l(
                                        _vm.emailTemplates.filter(function (x) {
                                          return (
                                            _vm.QuestionnaireType.id ===
                                            x.emailTemplateTypeCategoryId
                                          )
                                        }),
                                        function (opt) {
                                          return _c(
                                            "option",
                                            {
                                              key: opt.id,
                                              domProps: {
                                                value: opt.id,
                                                selected:
                                                  parseInt(opt.id) ===
                                                  parseInt(
                                                    _vm.input
                                                      .defaultSentEmailTemplate
                                                  ),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(opt.name) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                      !_vm.isViewOnly
                        ? _c("div", { staticClass: "columns" }, [
                            _c("div", { staticClass: "column" }, [
                              _vm._m(9),
                              _c(
                                "div",
                                { staticClass: "invite-client-wrapper" },
                                [
                                  _c("InviteClients", {
                                    attrs: {
                                      model: this.invitationInput,
                                      selectedClients: this.input.invitations,
                                      clients: this.input.systemClients,
                                      institutionId: this.input.institution.id,
                                    },
                                    on: {
                                      invitationsUpdated:
                                        _vm.onInvitationsUpdated,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "columns" }, [
                        _c("div", { staticClass: "column" }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "20px",
                                "text-align": "left",
                              },
                            },
                            [
                              !_vm.isViewOnly
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "heading",
                                      staticStyle: { "font-size": "20px" },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.name) + " ")]
                                  )
                                : _vm._e(),
                              false
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "subheading",
                                      staticStyle: {
                                        padding: "0px 0px 7px 0px",
                                        "margin-bottom": "1.5rem",
                                        "border-bottom": "solid 1px lightgray",
                                      },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v("Bank Contact Information"),
                                      ]),
                                      _c("br"),
                                      _vm._v(
                                        " Please review and correct the information as needed. "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              false
                                ? _c("div", { staticClass: "columns" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "column flex-container flex-full",
                                        staticStyle: {
                                          "flex-wrap": "wrap",
                                          gap: "0.5rem !important",
                                          padding: "0.5rem !important",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "field",
                                            staticStyle: { width: "100%" },
                                          },
                                          [
                                            _vm._m(10),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "control-center",
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.input.clientAddress,
                                                      expression:
                                                        "input.clientAddress",
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    name: "legal address",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.input.clientAddress,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.input,
                                                        "clientAddress",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.errors.has(
                                                            "legal address"
                                                          ),
                                                        expression:
                                                          "errors.has('legal address')",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "help is-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.errors.first(
                                                          "legal address"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "field",
                                            staticStyle: {
                                              "max-width":
                                                "calc(50% - 0.25rem) !important",
                                            },
                                          },
                                          [
                                            _vm._m(11),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "control-center",
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.input
                                                          .clientAddressCity,
                                                      expression:
                                                        "input.clientAddressCity",
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    name: "legal address",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.input
                                                        .clientAddressCity,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.input,
                                                        "clientAddressCity",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.errors.has(
                                                            "legal address"
                                                          ),
                                                        expression:
                                                          "errors.has('legal address')",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "help is-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.errors.first(
                                                          "legal address"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "field",
                                            staticStyle: {
                                              "max-width":
                                                "calc(15% - 0.25rem) !important",
                                            },
                                          },
                                          [
                                            _vm._m(12),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "control-center",
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.input
                                                          .clientAddressState,
                                                      expression:
                                                        "input.clientAddressState",
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    name: "legal address",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.input
                                                        .clientAddressState,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.input,
                                                        "clientAddressState",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.errors.has(
                                                            "legal address"
                                                          ),
                                                        expression:
                                                          "errors.has('legal address')",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "help is-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.errors.first(
                                                          "legal address"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "field",
                                            staticStyle: {
                                              "max-width":
                                                "calc(34% - 0.25rem) !important",
                                            },
                                          },
                                          [
                                            _vm._m(13),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "control-center",
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.input
                                                          .clientAddressPostal,
                                                      expression:
                                                        "input.clientAddressPostal",
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    name: "legal address",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.input
                                                        .clientAddressPostal,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.input,
                                                        "clientAddressPostal",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.errors.has(
                                                            "legal address"
                                                          ),
                                                        expression:
                                                          "errors.has('legal address')",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "help is-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.errors.first(
                                                          "legal address"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "column flex-container flex-full",
                                        staticStyle: {
                                          "flex-wrap": "wrap",
                                          gap: "0.5rem !important",
                                          padding: "0.5rem !important",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "field",
                                            staticStyle: { width: "100%" },
                                          },
                                          [
                                            _vm._m(14),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "control-center",
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.input
                                                          .clientMailingAddress,
                                                      expression:
                                                        "input.clientMailingAddress",
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    name: "legal address",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.input
                                                        .clientMailingAddress,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.input,
                                                        "clientMailingAddress",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.errors.has(
                                                            "legal address"
                                                          ),
                                                        expression:
                                                          "errors.has('legal address')",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "help is-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.errors.first(
                                                          "legal address"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "field",
                                            staticStyle: {
                                              "max-width":
                                                "calc(50% - 0.25rem) !important",
                                            },
                                          },
                                          [
                                            _vm._m(15),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "control-center",
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.input
                                                          .clientMailingAddressCity,
                                                      expression:
                                                        "input.clientMailingAddressCity",
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    name: "legal address",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.input
                                                        .clientMailingAddressCity,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.input,
                                                        "clientMailingAddressCity",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.errors.has(
                                                            "legal address"
                                                          ),
                                                        expression:
                                                          "errors.has('legal address')",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "help is-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.errors.first(
                                                          "legal address"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "field",
                                            staticStyle: {
                                              "max-width":
                                                "calc(15% - 0.25rem) !important",
                                            },
                                          },
                                          [
                                            _vm._m(16),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "control-center",
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.input
                                                          .clientMailingAddressState,
                                                      expression:
                                                        "input.clientMailingAddressState",
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    name: "legal address",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.input
                                                        .clientMailingAddressState,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.input,
                                                        "clientMailingAddressState",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.errors.has(
                                                            "legal address"
                                                          ),
                                                        expression:
                                                          "errors.has('legal address')",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "help is-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.errors.first(
                                                          "legal address"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "field",
                                            staticStyle: {
                                              "max-width":
                                                "calc(34% - 0.25rem) !important",
                                            },
                                          },
                                          [
                                            _vm._m(17),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "control-center",
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.input
                                                          .clientMailingAddressPostal,
                                                      expression:
                                                        "input.clientMailingAddressPostal",
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    name: "legal address",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.input
                                                        .clientMailingAddressPostal,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.input,
                                                        "clientMailingAddressPostal",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.errors.has(
                                                            "legal address"
                                                          ),
                                                        expression:
                                                          "errors.has('legal address')",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "help is-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.errors.first(
                                                          "legal address"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "column flex-container flex-full",
                                        staticStyle: {
                                          "flex-wrap": "wrap",
                                          gap: "0.5rem !important",
                                          padding: "0.5rem !important",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "field",
                                            staticStyle: {
                                              padding: "0 !important",
                                              margin: "0px !important",
                                            },
                                          },
                                          [
                                            _vm._m(18),
                                            _c(
                                              "div",
                                              { staticClass: "control-center" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.input.clientPhone,
                                                      expression:
                                                        "input.clientPhone",
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    name: "business phone",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.input.clientPhone,
                                                  },
                                                  on: {
                                                    keydown: _vm.forceFormat,
                                                    keyup: _vm.formatPhoneInput,
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.input,
                                                        "clientPhone",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.errors.has(
                                                            "business phone"
                                                          ),
                                                        expression:
                                                          "errors.has('business phone')",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "help is-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.errors.first(
                                                          "business phone"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("div", { staticClass: "field" }, [
                                          _vm._m(19),
                                          _c(
                                            "div",
                                            { staticClass: "control-center" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.input.clientFax,
                                                    expression:
                                                      "input.clientFax",
                                                  },
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'",
                                                  },
                                                ],
                                                attrs: { name: "fax number" },
                                                domProps: {
                                                  value: _vm.input.clientFax,
                                                },
                                                on: {
                                                  keydown: _vm.forceFormat,
                                                  keyup: _vm.formatPhoneInput,
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.input,
                                                      "clientFax",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.errors.has(
                                                          "fax number"
                                                        ),
                                                      expression:
                                                        "errors.has('fax number')",
                                                    },
                                                  ],
                                                  staticClass: "help is-danger",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "fax number"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              false
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "subheading",
                                      staticStyle: {
                                        padding: "0px 0px 7px 0px",
                                        "margin-bottom": "0.5rem",
                                        "border-bottom": "solid 1px lightgray",
                                        position: "relative",
                                      },
                                    },
                                    [
                                      _c("b", [_vm._v("Contact Information")]),
                                      _c(
                                        "div",
                                        { staticClass: "show-deleted" },
                                        [
                                          _vm.hasDeletedContacts
                                            ? _c(
                                                "button",
                                                {
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showDeletedContacts =
                                                        !_vm.showDeletedContacts
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.showDeletedContacts
                                                          ? "Hide"
                                                          : "Show"
                                                      ) +
                                                      " Deleted Contacts "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          !_vm.isViewOnly
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "button is-warning is-small",
                                                  staticStyle: {
                                                    "margin-left": "1rem",
                                                  },
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.resetContactTable()
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Reset Contacts ")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " Please review and adjust entries as needed. "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              false
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "table",
                                      staticStyle: { "min-width": "100%" },
                                    },
                                    [
                                      _c("DataTable", {
                                        staticStyle: { "min-width": "100%" },
                                        attrs: {
                                          "header-fields": _vm.contactFields,
                                          tableData: _vm.filteredContacts,
                                          "is-loading": _vm.isLoading,
                                          css: _vm.datatableCss,
                                          disableAddRow: _vm.isViewOnly,
                                          limit: 100,
                                          "not-found-msg": "No contacts found",
                                        },
                                        on: {
                                          "on-add-row": function ($event) {
                                            return _vm.addRow(
                                              _vm.contactTable,
                                              "contactTable"
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "actions",
                                              fn: function (props) {
                                                return !_vm.isViewOnly
                                                  ? _c("div", {}, [
                                                      props &&
                                                      props.rowData &&
                                                      props.rowData
                                                        .isDeleted === 0
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "button is-light",
                                                              staticStyle: {
                                                                "max-width":
                                                                  "50px",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    props
                                                                      .rowData
                                                                      .contactId &&
                                                                    props
                                                                      .rowData
                                                                      .contactId !=
                                                                      0
                                                                      ? (props.rowData.isDeleted = 1)
                                                                      : _vm.removeRow(
                                                                          _vm.contactTable,
                                                                          props,
                                                                          "contactTable"
                                                                        )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("-")]
                                                          )
                                                        : _vm._e(),
                                                      props &&
                                                      props.rowData &&
                                                      props.rowData
                                                        .isDeleted === 1
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "button is-light",
                                                              staticStyle: {
                                                                "max-width":
                                                                  "50px",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    props.rowData.isDeleted = 0
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("+")]
                                                          )
                                                        : _vm._e(),
                                                    ])
                                                  : _vm._e()
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _c("br"),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "subheading",
                                  staticStyle: {
                                    padding: "0px 0px 7px 0px",
                                    "margin-bottom": "0.5rem",
                                    "border-bottom": "solid 1px lightgray",
                                    position: "relative",
                                  },
                                },
                                [
                                  _c("b", [
                                    _vm._v("Participant Basic Information"),
                                  ]),
                                  _c("br"),
                                  _vm._v(
                                    " Please review and adjust entries as needed. "
                                  ),
                                  _c("div", { staticClass: "show-deleted" }, [
                                    _vm.hasDeletedParticipants
                                      ? _c(
                                          "button",
                                          {
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                _vm.showDeletedParticipants =
                                                  !_vm.showDeletedParticipants
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.showDeletedParticipants
                                                    ? "Hide"
                                                    : "Show"
                                                ) +
                                                " Deleted Participants "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !_vm.isViewOnly
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "button is-warning is-small",
                                            staticStyle: {
                                              "margin-left": "1rem",
                                            },
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.resetParticipantsTable()
                                              },
                                            },
                                          },
                                          [_vm._v(" Reset Participants ")]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "min-width": "100%" } },
                                [
                                  _c("DataTable", {
                                    staticStyle: { "min-width": "100%" },
                                    attrs: {
                                      "header-fields": _vm.participantFields,
                                      tableData: _vm.filteredParticipants,
                                      "is-loading": _vm.isLoading,
                                      css: _vm.datatableCss,
                                      disableAddRow: _vm.isViewOnly,
                                      limit: 100,
                                      "not-found-msg": "No participants found",
                                    },
                                    on: {
                                      "on-add-row": function ($event) {
                                        return _vm.addRow(
                                          _vm.participantTable,
                                          "participantTable"
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "actions",
                                          fn: function (props) {
                                            return !_vm.isViewOnly
                                              ? _c("div", {}, [
                                                  props &&
                                                  props.rowData &&
                                                  props.rowData.isDeleted === 0
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "button is-light",
                                                          staticStyle: {
                                                            "max-width": "50px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              props.rowData
                                                                .participantId &&
                                                              props.rowData
                                                                .participantId !=
                                                                0
                                                                ? (props.rowData.isDeleted = 1)
                                                                : _vm.removeRow(
                                                                    _vm.participantTable,
                                                                    props,
                                                                    "participantTable"
                                                                  )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("-")]
                                                      )
                                                    : _vm._e(),
                                                  props &&
                                                  props.rowData &&
                                                  props.rowData.isDeleted === 1
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "button is-light",
                                                          staticStyle: {
                                                            "max-width": "50px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              props.rowData.isDeleted = 0
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("+")]
                                                      )
                                                    : _vm._e(),
                                                ])
                                              : _vm._e()
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.showPlanTypeModal
                            ? _c(
                                "div",
                                {
                                  staticClass: "modal is-active",
                                  attrs: { id: "plan-type-modal" },
                                },
                                [
                                  _c("div", {
                                    staticClass: "modal-background",
                                    on: {
                                      click: function ($event) {
                                        _vm.showPlanTypeModal = false
                                      },
                                    },
                                  }),
                                  _c("div", { staticClass: "modal-card" }, [
                                    _c(
                                      "header",
                                      { staticClass: "modal-card-head" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "modal-card-title" },
                                          [_vm._v("Add New Plan Type")]
                                        ),
                                        _c("a", {
                                          staticClass: "delete",
                                          attrs: { "aria-label": "close" },
                                          on: {
                                            click: function ($event) {
                                              _vm.showPlanTypeModal = false
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "section",
                                      { staticClass: "modal-card-body" },
                                      [
                                        _vm.planTypeExists
                                          ? _c(
                                              "div",
                                              { staticClass: "content" },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    "Plan Type '" +
                                                      _vm._s(
                                                        _vm.newPlanType.toUpperCase()
                                                      ) +
                                                      "' already exists."
                                                  ),
                                                ]),
                                                _vm._v(
                                                  " Please select it from the list or enter a new Plan Type. "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("div", { staticClass: "field" }, [
                                          _c(
                                            "div",
                                            { staticClass: "control" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.newPlanType,
                                                    expression: "newPlanType",
                                                  },
                                                  {
                                                    name: "focus",
                                                    rawName: "v-focus",
                                                  },
                                                ],
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  placeholder:
                                                    "Enter a new Plan Type Code",
                                                },
                                                domProps: {
                                                  value: _vm.newPlanType,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.newPlanType =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "footer",
                                      { staticClass: "modal-card-foot" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "button is-accent has-text-white",
                                            on: { click: _vm.addPlanType },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Save Plan Type"),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "button",
                                            on: {
                                              click: function ($event) {
                                                _vm.showPlanTypeModal = false
                                              },
                                            },
                                          },
                                          [_vm._v("Cancel")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Questionnaire Properties"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Name "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Due Date "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Info 'As Of' Date "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label", attrs: { for: "" } }, [
      _vm._v(" Portal Message "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Reminder Schedule"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Reminder Date/Time "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Email Template "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v('On "Send" Notification'),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Invite Clients"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" Legal Address "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" City "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" ST "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" Postal Code "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" Mailing Address "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" City "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" ST "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" Postal Code "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" Main Phone "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" Main Fax "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }